// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query AuraStoriesPageWrapperQuery {
    allAuraStoriesJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className = null, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allAuraStoriesJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['publishedTimestamp', 'asc'])

      const {
        pageContext: {
          intl: { originalPath },
        },
      } = rest

      return (
        <StandardPageWrapper
          {...rest}
          className={classNames(className, 'aura-story-page', 'explorer')}
        >
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {children}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <aside
                style={{
                  borderTop: '2px solid var(--orange1)',
                  marginTop: '1.1rem',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                  borderBottomLeftRadius: '0.55rem',
                  borderBottomRightRadius: '0.55rem',
                  overflow: 'hidden',
                }}
              >
                <h2>More stories...</h2>
                <Row
                  gutter={[
                    { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12 },
                    { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12 },
                  ]}
                >
                  {map(ordered, ({ title, routeSlug, cover }) => (
                    <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <Link
                        className={classNames({
                          active: originalPath === routeSlug,
                        })}
                        to={routeSlug}
                        style={{
                          position: 'relative',
                          borderRadius: '0.55rem',
                          display: 'block',
                          overflow: 'hidden',
                        }}
                      >
                        <GatsbyImage image={getImage(cover)} />
                        <span
                          style={{
                            position: 'absolute',
                            right: 0,
                            background: 'var(--gold1)',
                            padding: '0.1255rem',
                          }}
                        >
                          {title}
                        </span>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </aside>
            </Col>
          </Row>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
