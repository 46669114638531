// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import BlockParser from '../block-parser'
import '../block-parser/style.less'

import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import AuraStoryPageWrapper from '../aura-story-page-wrapper'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query AuraStoryPageTemplateQuery($routeSlug: String) {
    allAuraStoriesJson(filter: { routeSlug: { eq: $routeSlug } }) {
      nodes {
        id
        contentBlocks {
          content
          type
          resources {
            resource {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          contentBlocks {
            type
            content
          }
        }
        publishedTimestamp
        routeSlug
        shortAbstract
        title
        video
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allAuraStoriesJson: {
          nodes: [{ title, routeSlug, publishedTimestamp, contentBlocks }],
        },
      },
      pageContext: { next, prev },
    } = this.props

    const dateTime = new Date(publishedTimestamp * 1000).toLocaleDateString(
      'en-US'
    )

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Aura Stories', slug: '/aura-stories' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    return (
      <AuraStoryPageWrapper
        className="case-study-page"
        pageSchema={pageSchema}
        {...this.props}
      >
        <Breadcrumbs
          breadcrumbs={pageSchema.breadcrumbs}
          prev={prev}
          next={next}
        />
        <h1>{title}</h1>
        {map(contentBlocks, (block) => (
          <BlockParser block={block} />
        ))}
      </AuraStoryPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
